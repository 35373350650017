<script setup>
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import Cookies from 'js-cookie';

const route = useRoute();
const gtm = useGtm();
const props = defineProps({
    error: {
        type: Object,
        required: true,
    },
});
const errorMessage = computed(() => {
    return (
        props.error.message ||
        'An unexpected error occurred. Please try again later.'
    );
});

onMounted(() => {
    gtm.trackView(document.title, route.fullPath);

    gtm.trackEvent({
        event: `page${props.error.statusCode || 'Unknown'}`,
        exod_id: store.user?.id,
        PageURL: route.fullPath,
        ReferrerURL: document.referrer,
    });
});
</script>

<template>
    <div
        class="flex min-h-screen flex-col items-center justify-center bg-red-100 text-red-700"
    >
        <h1 class="mb-4 text-4xl font-bold">Error</h1>
        <p class="mb-6 text-lg">{{ errorMessage }}</p>
        <p v-if="props.error.statusCode" class="mb-6 text-sm text-red-500">
            Error Code: {{ props.error.statusCode }}
        </p>
        <svg
            class="mb-6 h-24 w-24"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
        >
            <path
                d="M12 2C10.8954 2 10 2.89543 10 4H14C14 2.89543 13.1046 2 12 2ZM8 4C8 2.34315 9.34315 1 11 1H13C14.6569 1 16 2.34315 16 4H18C19.1046 4 20 4.89543 20 6V8H4V6C4 4.89543 4.89543 4 6 4H8ZM4 10H20V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V10ZM6 12V20H18V12H6ZM8 14H10V16H8V14ZM14 14H16V16H14V14Z"
            />
        </svg>
    </div>
</template>
